import React, { ReactNode } from "react";
import { observer } from "mobx-react-lite";

interface IWardBayContainerProps {
    children? : ReactNode
}
const WardBayContainer: React.FC<IWardBayContainerProps> = ({children}) => {
    
    return (
        <div className="container-fluid pt-2">
            <div className="row">
                {children}
            </div>
        </div>
    )
}
export default observer(WardBayContainer);