import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import { observer } from "mobx-react-lite";
import WardHeader from "./WardHeader";
import WardBayContainer from "./WardBayContainer";
import { IInpatient } from "app/models/inpatient";
import WardBed from "./WardBed";
import WardBay from "./WardBay";
import agent from "app/api/agent";
import DataLoadErrorAlert from "features/patientrecord/DataLoadErrorAlert";

const WardLayout: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const {location} = useParams();
    const [inpatients, setInpatients] = useState<IInpatient[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setIsLoading(true);
        agent.Inpatient.list({location: location, isAnonymised: false, role: "" })
        .then(res => setInpatients(res))
        .catch(err => setError(err))
        .finally(() => setIsLoading(false))
    }, []);

    const Rooms = ()  : string[] => {
        return [...new Set(inpatients?.map(r => r.currentLocation.room.description))];
    }

    const Beds = (room: string) : string[] => {
        return [...new Set(inpatients?.filter(r => r.currentLocation.room.description === room).map(r => r.currentLocation.bedNumber))];
    }

    const BedPatient = (room: string, bed: string) : IInpatient | null => {
        return inpatients ? inpatients.filter(r => r.currentLocation.room.description === room && r.currentLocation.bedNumber === bed)[0] : null;
    }

    return (
        <>
            <WardHeader location={location} />
            {error ? <DataLoadErrorAlert error={error} /> : <></>}
            {!isLoading && inpatients.length ?
            <WardBayContainer>
                {Rooms().map((room: string, index: number) => (
                <WardBay key={index} room={room} bedCount={Beds(room).length}>
                    {Beds(room).map((bed: string, index: number) => (
                    <WardBed key={index} bedCount={Beds(room).length} patient={BedPatient(room, bed)} />
                    ))}
                </WardBay>
                ))}
            </WardBayContainer> :
            <>Loading...</>}
        </>
    )
}
export default observer(WardLayout);