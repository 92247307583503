import React, { useEffect, useContext } from "react";
import { Switch, Route } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Row, Col, Container, Card } from 'reactstrap'
import { RootStoreContext } from "../../app/stores/rootStore";
import PatientRecordHeader from "./PatientRecordHeader";
import PatientRecordNavBar from "./PatientRecordNavBar";
import PatientRecordAllergiesList from "./PatientRecordAllergiesList";
import PatientRecordProblemsList from "./PatientRecordProblemsList";
import PatientRecordAppointmentsList from "./PatientRecordAppointmentsList";
import PatientRecordAdmissionsList from "./PatientRecordAdmissionsList";
import PatientRecordMedicationsList from "./PatientRecordMedicationsList";
import PatientRecordInvestigationsList from "./PatientRecordInvestigationsList";
import PatientRecordMig from "./PatientRecordMig";
import problemsImg from '../../app/images/stethoscope.png';
import allergiesImg from '../../app/images/rash.png';
import appointmentsImg from '../../app/images/medical-appointment.png';
import attentanceImg from '../../app/images/ambulance.png';
import admissionImg from '../../app/images/hospital-bed.png';
import documentImg from '../../app/images/file.png';
import medicationImg from '../../app/images/medication.png';
import summaryImg from '../../app/images/summary.png';
import resultImg from '../../app/images/flask.png';
import yhcrImg from '../../app/images/yhcr.png';
import doctorImg from '../../app/images/doctor.png';
import PatientRecordDocumentsList from "./PatientRecordDocumentsList";
import PatientRecordDocumentView from "./PatientRecordDocumentView";
import PatientRecordAttendancesList from "./PatientRecordAttendancesList";
import PatientRecordStore from "app/stores/patientRecordStore";
import PatientRecordSummary from "./PatientRecordSummary";
import PatientRecordYHCR from "./PatientRecordYHCR";
export interface IPatientNav {
    route: string,
    displayOnMenu: boolean,
    imageSrc: string,
    title: string,
    component: React.ReactNode
}

const PatientRecordPageLayout: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const patientRecordStore = useContext(PatientRecordStore);
    const urlParams = new URLSearchParams(window.location.search);
    const getPageRoute = (routePath:string) => `${process.env.REACT_APP_URL_PATIENT_RECORD}${routePath}`
    
    const menuItems: IPatientNav[] = [
        { route: "/summary", title: "Summary", displayOnMenu: true, imageSrc: summaryImg, component: <PatientRecordSummary />},
        { route: "/problems", title: "Problems", displayOnMenu: true, imageSrc: problemsImg, component: <PatientRecordProblemsList /> },
        { route: "/allergies", title: "Allergies", displayOnMenu: true, imageSrc: allergiesImg, component: <PatientRecordAllergiesList /> },
        { route: "/appointments", title: "Appointments", displayOnMenu: true, imageSrc: appointmentsImg, component: <PatientRecordAppointmentsList /> },
        { route: "/admissions", title: "Admissions", displayOnMenu: true, imageSrc: admissionImg, component: <PatientRecordAdmissionsList /> },
        { route: "/attendances", title: "Attendances", displayOnMenu: true, imageSrc: attentanceImg, component: <PatientRecordAttendancesList /> },
        { route: "/medications", title: "Medications", displayOnMenu: true, imageSrc: medicationImg, component: <PatientRecordMedicationsList /> },
        { route: "/document", title: "Document", displayOnMenu: false, imageSrc: documentImg, component: <PatientRecordDocumentView /> },
        { route: "/documents", title: "Documents", displayOnMenu: process.env.REACT_APP_IS_ANONYMOUS === "true" ? false : true, imageSrc: documentImg, component: <PatientRecordDocumentsList /> },
        { route: "/investigations", title: "Investigations", displayOnMenu: true, imageSrc: resultImg, component: <PatientRecordInvestigationsList /> },
        { route: "/yhcr", title: "YHCR", displayOnMenu: rootStore.userStore.user.roles.filter(r => r.userAccess?.some(ua => ua.name === 'YHCR')).length > 0, imageSrc: yhcrImg, component: <PatientRecordYHCR /> },
        { route: "/mig", title: "GP Data (MIG)", displayOnMenu: true, imageSrc: doctorImg, component: <PatientRecordMig /> }
    ];

    useEffect(() => {    
        let mis = menuItems.filter(mi => getPageRoute(mi.route) === window.location.pathname);
        if (mis.length) {

            let audit = {
                module: "PatientRecord",
                description: `${rootStore.userStore.user.username} has accessed ${mis[0].title}`,
                username: rootStore.userStore.user.username,
                action: mis[0].title,
                page: window.location.pathname,
                documentId: urlParams.get("d"),
                patientId: urlParams.get("p"),
                visitId: urlParams.get("v")
            };
            rootStore.auditStore.addEntry(audit);
        }     
    },[,window.location.pathname]);

    return (
        <div className="d-flex flex-column h-100">
            <main role="main" className="flex-shrink-0 mt-0">
                <Container fluid={true}>
                    <Row>
                        <PatientRecordNavBar menuItems={menuItems} />  
                        <Col>                      
                        <PatientRecordHeader />
                            <Card body className="card-menu card-patient-record my-0 mx-0 m-sm-3 m-lg-5 py-3 px-4">    
                                {patientRecordStore.patient ? 
                                <Switch>                                    
                                    {menuItems.map((menuItem: IPatientNav, index: number) => 
                                    <Route
                                        key={index}
                                        path={getPageRoute(menuItem.route)}
                                        children={() => <>
                                            <h3 className="mb-3 text-primary text-gradient">{menuItem.title}</h3>
                                            {menuItem.component}
                                            </>}
                                    />
                                    )}
                                </Switch>
                                : <>Loading...</> }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    )

}
export default observer(PatientRecordPageLayout)