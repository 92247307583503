import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col } from "reactstrap";
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "../common/DateTimeFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faUserMd } from "@fortawesome/free-solid-svg-icons";
import { IFhirAppointment, IFhirExtension_v3, IFhirExtension_v4 } from "app/models/fhir";

interface IFhirAppointmentProps {
    appointment: IFhirAppointment
}

const YhcrAppointments: React.FC<IFhirAppointmentProps> = ({ appointment }) => {
    
    const getParticipants = (type: string) => 
        appointment.participant.filter(a => a.type.length && a.type[0].coding[0].display?.value === type)

    const locationDisplay = () => getParticipants("location").map((p,i) => <div><FontAwesomeIcon icon={faMapMarker} /> {p.actor.display?.value}</div>)
    const consultantDisplay = () => getParticipants("consultant").map((p,i) => <div key={i}><FontAwesomeIcon icon={faUserMd} className="text-secondary" /> <strong>{p.actor.display?.value}</strong> : consultant : {p.required?.value}</div>)
    const pperformerDisplay = () => getParticipants("primary performer").map((p,i) => <div key={i}><FontAwesomeIcon icon={faUserMd} className="text-primary" /> <strong>{p.actor.display?.value}</strong> : primary performer : {p.required?.value}</div>)

    return (
        <Fragment>
            <Col className="py-2">        
                <div className="mb-2">   
                        {appointment.specialty && appointment.specialty.map((s, i) => (
                            <span className="font-weight-bold text-sm pr-2 py-1 mr-2 text-lg" key={i}>{s.coding[0].display?.value}</span>
                        ))} 
                        {appointment.serviceType && appointment.serviceType.map((s, i) => (
                            <span className="font-weight-bold text-sm pr-2 py-1 mr-2 text-lg" key={i}>{s.coding[0].display?.value}</span>
                        ))} 
                        {appointment.extension?.map((e, i) => (
                            e.url?.value === "https://fhir.hl7.org.uk/STU3/CodeSystem/CareConnect-DeliveryChannel-1" ?
                            <span className={`text-uppercase text-sm px-2 py-1 border font-weight-bold text-`}>{(e as IFhirExtension_v3).valueCode}</span> : <></>
                        ))}    
                        {appointment.appointmentType?.coding.map((t, i) => (
                            <span className={`text-uppercase text-sm px-2 py-1 border font-weight-bold text-${t.code?.value.toLowerCase() === "routine" ? "info" : "success"} rounded mx-2`} key={i}>{t.code?.value.toLowerCase()}</span>
                        ))} 
                        <div className="d-block mt-3 text-sm text-secondary ">
                            {pperformerDisplay()}
                            {consultantDisplay()}
                            {locationDisplay()}    
                        </div>
                </div>                   
            </Col>
            <Col xs="2" className="text-center py-2">
                <div className="text-xs">Start</div>
                <div className="d-inline-block shadow-sm align-top rounded border border-secondary">
                    <div className="text-sm py-1 px-2 text-white bg-secondary text-center">
                        {ShortDateOnlyFormat(appointment.start?.value)}
                    </div>
                    <div className="py-2 text-center font-weight-bold">
                        {ShortTimeOnlyFormat(appointment.start?.value)}
                    </div>
                </div>
            </Col>
            <Col xs="2" className="py-2 text-center">
                <span className="badge text-md">{appointment.status?.value}</span>
                <div>
                {appointment.extension?.map((e, i) => (
                            e.url?.value === "https://fhir.yhcr.nhs.uk/StructureDefinition/Extension-Interweave-R4AppointmentCancellationReason" ?
                            <span className={`text-sm text-danger px-2 py-1`}>{(e as IFhirExtension_v4).valueCodeableConcept.coding[0].display?.value}</span> : <></>
                        ))}  
                </div>
            </Col>
        </Fragment>
    )

}
export default observer(YhcrAppointments)