import React, { useContext, Fragment, useEffect, useState }  from "react";
import { PopoverHeader, PopoverBody, UncontrolledPopover, Card, ListGroup, ListGroupItem } from 'reactstrap'
import { observer } from "mobx-react-lite";
import Loader from 'react-loader-spinner'
import NhsNumber from "features/common/NhsNumber";
import PatientRecordStore from "app/stores/patientRecordStore";
import { Patient, PatientAllergy } from "app/models/inpatient";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";

const PatientRecordHeader: React.FC = () => {
    const patientRecordStore = useContext(PatientRecordStore);
    const [ patient, setPatient ] = useState<Patient>(null)
    const [ allergies, setAllergies ] = useState<PatientAllergy[]>(null)
    let urlParams = new URLSearchParams(window.location.search);       

    useEffect(() => {
        if (!patientRecordStore.patient || patientRecordStore.patient.systemInternalIdentifier.value !== urlParams.get('p')) {
            patientRecordStore.getPatient(urlParams.get('p'))
        } else {
            setPatient(patientRecordStore.patient);
        }
    }, [patientRecordStore.patient]);

    useEffect(() => {
        if (patientRecordStore.allergies) {
            setAllergies(patientRecordStore.allergies);
        }
    }, [patientRecordStore.allergies]);


    return (
        <Fragment>

            <nav className="sticky-top">
                {patient ? (
                    <>
                        <div className="row bg-secondary text-white">
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="my-0 my-md-2">
                                    <div className="card-body pt-0 pb-1">
                                        <div className="badge-space mb-2">
                                            <div className="badge-primary mr-1 badge text-center">
                                                NHS:<NhsNumber nhsNumber={patient?.nhsNumber?.value} />
                                            </div>
                                            <div className="badge-primary mr-1 badge text-center">
                                                {patient.systemPublicIdentifier.value}
                                            </div>
                                        </div>
                                        <div className="h4 font-weight-bold">{patient.name.forename} {patient.name.surname}<span className="h6 ml-3">{patient.gender}, {patient.age}</span></div>
                                        <p className="small">   {patient.contactDetails.address.line1 ? patient.contactDetails.address.line1 + ', ' : ''}
                                            {patient.contactDetails.address.line2 ? patient.contactDetails.address.line2 + ', ' : ''}
                                            {patient.contactDetails.address.line3 ? patient.contactDetails.address.line3 + ', ' : ''}
                                            {patient.contactDetails.address.postcode ? patient.contactDetails.address.postcode + '. ' : ''}                                                       </p>
                                        <span className="text-black">
                                            {/*<small><i className="fa fa-info-circle"></i> Currently in <span>{searchStore.currentLocation.room.description} {searchStore.currentLocation.bedType.description} {searchStore.currentLocation.bedNumber}</span></small>*/}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="my-0 my-md-2">
                                    <div className="card-body py-2 pb-3">
                                        <p className="h6 font-weight-bold">Registered GP Practice</p>
                                        {patient.gpDetails && (
                                            <>
                                                <p className="h5 font-weight-bold mb-1">{patient.gpDetails.registeredPracticeContactDetails.address.line1}</p>                                                
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="my-0 my-sm-2 pt-3">

                                    {allergies !== undefined && allergies !== null && (
                                        
                                        allergies.length > 0 && !allergies.some(x => x.allergy.id === 'NKA') ? (
                                        <Fragment>
                                            <Card className="ml-4 mx-2 d-inline px-2 py-2 col-1 text-center scale-hover shadow text-dark border text-lg font-weight-bold mt-2" id="KA" type="hover">
                                                Known Allergies
                                                <span className="abs badge badge-pill badge-danger text-xs" style={{ 'top': '-8px' }}>{allergies.length}</span>
                                            </Card>
                                            <div>
                                                <UncontrolledPopover style={{ 'width': '400px' }} trigger="hover" placement="bottom" target="KA" >
                                                    <PopoverHeader className="bg-header">Known Allergies</PopoverHeader>
                                                    <PopoverBody>
                                                        <ListGroup className="list-group list-group-flush p-0" style={{ lineHeight: 1.15 }}>
                                                            {allergies.map((allergy, index) => (
                                                                <ListGroupItem key={index} className="p-2">
                                                                    <span className="badge badge-warning badge-pill float-right text-xs text-uppercase t-bold text-center">{allergy.status}</span>
                                                                    <div className="float-right mr-3 text-sm text-right t-medium text-muted">{ShortDateTimeFormat(allergy.addedDateTime)}</div>
                                                                    <div className="t-medium text-md t-bold">{allergy.allergy.description}  <span className="text-muted" style={{ 'fontSize': '0.7em' }}> </span></div>
                                                                    {allergy.comment && (
                                                                        <div className="small text-muted">{allergy.comment}</div>
                                                                    )}
                                                                </ListGroupItem>
                                                            ))}
                                                        </ListGroup>
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </div>
                                        </Fragment>
                                    ) :
                                        <p className="h5 font-weight-bold mb-1">NKA - No Known Allergies</p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </>
                ) : <Loader type="TailSpin" color="#007bff" className="text-center" height={80} width={80} />}
            </nav>
        </Fragment>

    )

}
export default observer(PatientRecordHeader)