import React, { Fragment, useContext } from 'react';
import { Route, withRouter } from 'react-router-dom';
import HomePage from '../features/home/HomePage';
import { observer } from 'mobx-react-lite';
import AdminHomepage from '../features/admin/AdminHomepage';
import { IUser } from './models/user';
import NoRoles from '../features/home/NoRoles';
import CareHomeCapacityMain from '../features/carehomes/capacity/CareHomeCapacityMain';
import MainNavbar from '../features/nav/MainNavbar';
import PatientSearch from '../features/search/PatientSearch';
import CarehomesMainLayout from '../features/carehomes/dashboard/MainLayout';
import PatientRecordPageLayout from 'features/patientrecord/PatientRecordPageLayout';
import { RootStoreContext } from './stores/rootStore';
import WardLayout from 'features/ward-viewer/WardLayout';
import PatientRecordYhcrPageLayout from 'features/patientrecord/PatientRecordYhcrPageLayout';

interface IProps {
    user: IUser
}
const ProtectedRoute: React.FC<IProps> = ({ user }) => {
    const rootStore = useContext(RootStoreContext);
    
    return (
        <Fragment>
            <MainNavbar />
            {user && rootStore.userStore.isLoggedIn ? (

                <Fragment>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/logout' component={HomePage} />
                    {user.roles.some(a => a.name === 'System Admin') && (
                        <Fragment>
                        <Route exact path={process.env.REACT_APP_URL_ADMIN_ROLES_ROOT} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_ROLES_ROOT}/add`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_ROLES_ROOT}/view`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_ROLES_ROOT}/edit`} component={AdminHomepage} />
                            <Route exact path={process.env.REACT_APP_URL_ADMIN_ORGANISATIONS_ROOT} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_ORGANISATIONS_ROOT}/add`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_ORGANISATIONS_ROOT}/edit`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_ORGANISATIONS_ROOT}/view`} component={AdminHomepage} />
                            <Route exact path={process.env.REACT_APP_URL_ADMIN_USERS_ROOT} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/edit`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/view`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/add`} component={AdminHomepage} />
                            <Route exact path={process.env.REACT_APP_URL_ADMIN_PRACTICES_ROOT} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_PRACTICES_ROOT}/edit`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_PRACTICES_ROOT}/view`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_PRACTICES_ROOT}/add`} component={AdminHomepage} />
                            <Route exact path={process.env.REACT_APP_URL_ADMIN_AUDIT_ROOT} component={AdminHomepage} />
                            <Route exact path='/location-viewer/:location' component={WardLayout} />
                        </Fragment>
                    )}
                    {user.roles.some(a => a.name.startsWith("Business Unit Admin")) && (
                        <Fragment>
                            <Route exact path={process.env.REACT_APP_URL_ADMIN_USERS_ROOT} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/edit`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/view`} component={AdminHomepage} />
                            <Route exact path={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/add`} component={AdminHomepage} />
                            <Route exact path={process.env.REACT_APP_URL_ADMIN_AUDIT_ROOT} component={AdminHomepage} />
                        </Fragment>
                    )}
                    {user.roles.some(r => r.userAccess?.some(x => x.name === 'Carehomes')) && (
                        <Route exact path={process.env.REACT_APP_URL_CAREHOMES_CAPACITY} component={CareHomeCapacityMain} />
                    )}
                    {user.roles.some(r => r.userAccess?.some(x => x.name === 'Patient Record')) && (
                        <>
                            <Route exact path="/patient/summary" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/problems" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/allergies" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/appointments" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/admissions" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/attendances" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/medications" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/mig" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/documents" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/document" component={PatientRecordPageLayout} />
                            <Route exact path="/patient/investigations" component={PatientRecordPageLayout} />
                        </>
                    )}
                    {user.roles.some(r => r.userAccess?.some(x => x.name === 'YHCR')) && (
                        <>
                            <Route path="/patient/yhcr/:resource" component={PatientRecordYhcrPageLayout} />
                            <Route exact path="/patient/yhcr" component={PatientRecordYhcrPageLayout} />
                        </>
                    )}
                    <Route exact path="/dashboard/carehomes" component={CarehomesMainLayout} />
                    {user.roles.some(r => r.userAccess?.some(x => x.name === 'Patient Search')) && (
                        <Route exact path={process.env.REACT_APP_URL_PATIENT_SEARCH} component={PatientSearch} />
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <NoRoles />
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/logout' component={HomePage} />
                </Fragment>
            )}
        </Fragment>
    )
}

export default withRouter(observer(ProtectedRoute));