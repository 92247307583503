import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "app/stores/rootStore";
import PatientRecordStore from "app/stores/patientRecordStore";
import yhcragent from "app/api/yhcragent";
import { IFhirAvailability } from "app/models/fhir";
import UserStore from "app/stores/userStore";

const PatientRecordYHCR: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const patientRecordStore = useContext(PatientRecordStore);
  const [ availability, setAvailability ] = useState<IFhirAvailability>()

  const loadData = () => {    

      let req =  { 
        nhsNumber: process.env.REACT_APP_IS_ANONYMOUS === "true" ? window.localStorage.getItem("yhcrSandpitPatient") : patientRecordStore.patient?.nhsNumber?.value, 
        isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true",
        yhcrJwt: window.localStorage.getItem("yhcrJwt")
         }
         console.log(req);
         return yhcragent.Fhir.das(req);
  }

  useEffect(() => {  
    let nhs = process.env.REACT_APP_IS_ANONYMOUS === "true" ? window.localStorage.getItem("yhcrSandpitPatient") : patientRecordStore.patient?.nhsNumber?.value
    
    if (nhs) {
      if (!yhcragent.JWT.exists() || !yhcragent.JWT.isValid()) {
        yhcragent.JWT.renew({
          nhsNumber: nhs,
          isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true",
          username: rootStore.userStore.user.username,
          forename: rootStore.userStore.user.displayName.includes(" ") ? rootStore.userStore.user.displayName.split(" ")[0] : rootStore.userStore.user.displayName,
          surname: rootStore.userStore.user.displayName.includes(" ") ? rootStore.userStore.user.displayName.split(" ")[1] : rootStore.userStore.user.displayName,
        })
        .then(() => loadData().then(das => setAvailability(das)))
      } else {
        loadData().then(das => setAvailability(das))
      }
    }
  }, [])

  const SandpitPatients = () : string[] => ['9657702402', '9657702100', '9657702070'] 

  const setSandpitPatient = (nhsNumber: string) => {
    window.localStorage.setItem("yhcrSandpitPatient", nhsNumber);
    let user = rootStore.userStore.user;
    yhcragent.JWT.renew({ 
      nhsNumber: nhsNumber, 
      username: user.username,
      forename: user.displayName.indexOf(' ') > -1 ? user.displayName.split(' ')[0] : user.displayName,
      surname: user.displayName.indexOf(' ') > -1 ? user.displayName.split(' ')[1] : user.displayName, 
      isAnonymous: process.env.REACT_APP_IS_ANONYMOUS === "true"});
    loadData().then((das: IFhirAvailability) => setAvailability(das))
  }


  return (
     <div>
      <p>
        The Yorkshire and Humber Care Record (YHCR) allows professionals with a Direct Care
        relationship to view shared information from other health and social organisations in 
        the Yorkshire and Humber region.
      </p>
      {process.env.REACT_APP_IS_ANONYMOUS === "true" ? 
      <p>
        You're using the Sandpit environment where you must select an NHS number below
        to see any data:
        <ul className="pl-0 ml-0 my-3">
          {SandpitPatients().map((p, i) => (
            <li className={`d-inline-block border border-${window.localStorage.getItem("yhcrSandpitPatient") === p ? "primary" : "secondary"} px-2 py-1 bg-light mr-2 font-weight-bold`} style={{cursor: 'pointer'}} onClick={() => setSandpitPatient(p)} key={`pi_${i}`}>{p}</li>
          ))}
        </ul>
      </p> : <></>}
      <div className="p-3 border-warning table-warning border mb-4 text-md">
      <p>
        The following organisations have data available for this patient:
      </p>
      <ul>
      {availability && availability.detail && availability.detail.length > 0 ? availability.detail?.map(d => d.organization?.dataProviders.map((p, i) => (
        <li key={i}>
            {p.name} {p.prefix ? <span className="font-weight-bold">{p.prefix}</span> : <></>}
        </li>
      ))) : <li>No data available.</li>}
      </ul>
      </div>
      <p className="font-weight-bold">
        Click a tab from the menu to show the data.
      </p>
    </div>
  );
};
export default observer(PatientRecordYHCR);
