import React, { useContext, useEffect } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import WhatsNew from './WhatsNew';
import NavMenu from './NavMenu';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import LoginForm from 'features/user/LoginForm';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignInButton } from 'app/azureauth/SignInButton';
import { SignOutButton } from 'app/azureauth/SignOutButton';
import { useHistory } from 'react-router-dom';

const HomePage = () => {
    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    
    const redirectAfterLogin = () => {        
        history.push(urlParams.get('login_redirect') !== null ? Buffer.from(urlParams.get('login_redirect'), "base64").toString("ascii") : '/')
    }

    useEffect(()=> {
        if (rootStore.userStore.isLoggedIn)
            redirectAfterLogin();
    }, [])

    return (
        <Container fluid className="pt-5 px-xl-5 px-3">
                <Row>
                    <Col md={4}>                        
                        <h3 className="text-gradient text-primary mb-4">Welcome to Rotherham Health Record</h3>
                        {process.env.REACT_APP_IS_ANONYMOUS === "true" ? (
                            <div className="text-xl alert bg-success text-white">
                                You're on the Anonymised Portal
                            </div>
                        ) : (
                            <div className="text-xl alert bg-danger text-white">
                                You're on the Live Portal &nbsp; ** All actions are audited **
                            </div>
                        )}
                        <Card className="card-menu text-xl lh-lg mt-3 mb-5">
                            <CardBody>
                                {user && (
                                <>           
                                <h3 className="text-gradient text-primary mb-4">
                                Hi <strong>{user.displayName}</strong>,
                                </h3>         
                                <p className="lh-lg mb-4">
                                    Use the <span className="d-none d-md-inline">right</span><span className="d-inline d-md-none">below</span> menu
                                    to navigate to the feature you require.  If you do not see the features you require, you probably do not have
                                    the correct access set up on your account.
                                </p>
                                </>
                                )}
                                <h5>Training for RHR.care</h5>
                                <p className="lh-lg mb-4">
                                    Training is available through the IT Development Team using a demo / anonymised version of this Portal. If you
                                    or your team require training, please contact the IT Development Team by email <a href="mailto:trft.sepia@nhs.net">trft.sepia@nhs.net</a>.
                                </p>
                                <h5>Support for Issues and Access</h5>
                                <p className="lh-lg mb-4">
                                    Contact the TRFT IT Service Desk if you have any issues with access or anything else on this portal by calling 
                                    (01709) 427970 or by emailing <a href="mailto:trft.itsupport@nhs.net">trft.itsupport@nhs.net</a>.
                                </p>
                                    
                            </CardBody>
                        </Card>
                        </Col>
                        <>
                            <Col md={4}> 
                            {rootStore.userStore.isLoggedIn && (<NavMenu />) || (
                                <>
                                    <LoginForm />
                                    <UnauthenticatedTemplate>
                                        <hr className="mt-3 mb-1" />
                                        <span className="text-sm">
                                            Or login with your Microsoft 365 Account 
                                            <SignInButton />
                                        </span>
                                    </UnauthenticatedTemplate> 
                                </>
                            )}
                            <AuthenticatedTemplate>
                                You are signed in with your Office 365 account.
                                <SignOutButton />
                            </AuthenticatedTemplate> 
                            </Col>
                            <Col md={4}>
                                <WhatsNew />                   
                            </Col>
                        </>
                </Row>
            </Container>
    )
}

export default HomePage