import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from 'reactstrap'
import { VisitEmergencyDepartment } from "app/models/emergencydepartment";
import agent from "app/api/agent";
import PatientRecordListDisplay, { IFilter, ISpecialFilter, ISort } from "./common/PatientRecordListDisplay";
import DateTimeTile from "./common/DateTimeTile";

const PatientRecordAttendancesList: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => {
    return agent.Patient.Attendances({
        systemIdentifier: urlParams.get("p"),
        patientIdentifier: null,
        visitIdentifier: null,
      })
  };

  const statusToVerb = (status: string) => {
    let newStatus = "";
    switch (status) {
        case "ADM IN":
            newStatus = "Current inpatient";
            break;
        case "PRE IN":
            newStatus = "Pre-admitted";
            break;
        case "DIS IN":
            newStatus = "Discharged";
            break;
        case "SCH IN":
            newStatus = "Scheduled";
            break;
        case "PRE REF":
        case "REG REF":
        case "DEP REF":
            newStatus = "Referral";
            break;
        default: 
            break;
    }
    return newStatus;
}

const statusToBadge = (status: string) => {
    let newStatus = "";
    let color = {
        foreground: "white",
        background: "secondary"
    }
    switch (status) {
        case "ADM IN":
            newStatus = "in-progress";
            color.background = "danger";
            break;
        case "PRE IN":
            newStatus = "pre-adm";
            color.background = "info";
            break;
        case "DIS IN":
            newStatus = "finished";
            color.background = "success";
            break;
        case "SCH IN":
            newStatus = "booked";
            color.background = "primary";
            break;
        case "PRE REF":
        case "REG REF":
        case "DEP REF":
            newStatus = "referral";
            color.background = "warning";
            color.foreground = "dark";
            break;
        default: 
            break;
    }
    return <span className={`badge bg-${color.background} badge-${color.background} text-${color.foreground} text-md float-right float-end`}>{newStatus}</span>
}

const displayTemplate = (attendance: VisitEmergencyDepartment, index: number) => {
  return (
    <li className={`list-group-item py-0 ${attendance.status.id === "REG ER" ? "list-group-item-warning" : ""}`} key={index}>
        <Row>
            <Col xs={8} sm={7} md={6} lg={7} className="py-1">
                <span className="font-weight-bold text-lg">{attendance.complaint?.description ? attendance.complaint.description : "(no complaint recorded)" }</span> 
                <div>
                  <DateTimeTile label="Arrived" date={attendance.admissionDetails?.admissionDateTime} />
                  <DateTimeTile label="Triaged" date={attendance.triageDateTime} />
                  <DateTimeTile label="Seen" date={attendance.seenDateTime} />
                  <DateTimeTile label="Discharged" date={attendance.dischargeDetails?.dischargeDateTime} />
                </div>
            </Col>
            <Col xs={4} sm={2} lg={2} className="py-1 font-weight-bold text-sm">
                {attendance.admissionDetails?.priority?.description}
            </Col>
            <Col xs={12} sm={3} md={4} lg={3} className="py-1">
                <span className="text-sm text-secondary">{attendance.systemPublicIdentifier.value}</span>
                {statusToBadge(attendance.status.id)}
            </Col>
        </Row>
    </li>
  )
}    

const filters: IFilter[] = [
    { label: "Attending", accessPath: "admissionDetails.attendingClinician.name", specialType: "Clinician" } as ISpecialFilter,
    { label: "Priority", accessPath: "admissionDetails.priority.description" },
    { label: "Method", accessPath: "admissionDetails.method.description" },
    { label: "Arrival", accessPath: "admissionDetails.admissionDateTime", specialType: "Date" } as ISpecialFilter
];

const sorts: ISort[] = [
    { label: "Arrival Date", accessPath: "admissionDetails.admissionDateTime", type: "Date" }
  ]

  return (
    <PatientRecordListDisplay _filters={filters} _sorts={sorts} _agentCall={loadData} _displayTemplate={displayTemplate} />
  )
};
export default observer(PatientRecordAttendancesList);
