import agent from 'app/api/agent';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, Container } from 'reactstrap';
import { IUser, IRole, IOrganisation } from "../../app/models/user";

interface IProps {
    users: IUser[],
    roles: IRole[],
    organisations: IOrganisation[]
}

const ViewUser: React.FC<IProps> = ({ users }) => {
    const [ user, setUser ] = useState<IUser>();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let email = params.get('u');

    useEffect(() => {
        agent.Admin.User({ "email": email}).then(u => setUser(u))
    }, [])

    return (
        (user && (
        <Container fluid>
            <h2 className="text-gradient text-primary mb-4">
                Viewing User - {user.displayName}
            </h2>
            <Row>
                <Col>
                    <div className="pb-3">
                        <Link to={`${process.env.REACT_APP_URL_ADMIN_USERS_ROOT}/edit/?u=${user.email}`} className="btn btn-primary">Edit</Link> &nbsp;
                        <Link to={process.env.REACT_APP_URL_ADMIN_USERS_ROOT} className="btn btn-link text-secondary">Back to Users</Link> &nbsp;
                    </div>
                    <Card className="card-menu mb-5 p-5" body>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Display Name:</dt>
                            <dd className="col-7">{user.displayName}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Username:</dt>
                            <dd className="col-7">{user.username}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Azure Username:</dt>
                            <dd className="col-7">{user.azureUsername || "-"}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Email:</dt>
                            <dd className="col-7">{user.email}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Job Title:</dt>
                            <dd className="col-7">{user.jobTitle || "-"}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Team:</dt>
                            <dd className="col-7">{user.team || "-"}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Manager:</dt>
                            <dd className="col-7">{user.manager || "-"}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Contact #:</dt>
                            <dd className="col-7">{user.contactNumber || "-"}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Department / Practice:</dt>
                            <dd className="col-7">{user.practice?.name || "-"}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Role(s):</dt>
                            <dd className="col-7">{user.roles.map((role: IRole, idx) => <Fragment key={`r_${idx}`}>[ {role.name} ]&nbsp;</Fragment>)}</dd>
                        </dl>
                        <dl className="row border-bottom mb-1">
                            <dt className="col-5 col-md-4 col-lg-3 col-xl-2">Organisation:</dt>
                            <dd className="col-7">{user.organisation?.name}</dd>
                        </dl>
                    </Card>
                </Col>
            </Row>
        </Container>
    )) || <>Loading...</>
    );
};

export default withRouter(ViewUser);