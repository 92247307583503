import { useContext, useState, useEffect } from 'react';
import { Form as FinalForm, Field } from 'react-final-form'
import { Button, Form, FormGroup, Label, Card,CardBody, CardHeader, Alert } from 'reactstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IUserFormValues } from '../../app/models/user';
import { FORM_ERROR } from 'final-form';
import { AddEntry } from '../../app/models/audit';
import { useMsal, useAccount } from "@azure/msal-react";
import { useHistory } from 'react-router-dom';

const LoginForm = () => {
    const rootStore = useContext(RootStoreContext);
    const { login } = rootStore.userStore;
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();

    useEffect(() => {
        if (account && account.name) {
            setName(account.username.split(" ")[0]);
            const azureLogin = {
                azureUsername: account.username,
                key: account.username
            };
            rootStore.userStore.Azurelogin(azureLogin).then(() => {   
                redirectAfterLogin();
            });
        } else {
            setName("");
        }
    }, [account]);

    const redirectAfterLogin = () => {        
        history.push(urlParams.get('login_redirect') !== null ? Buffer.from(urlParams.get('login_redirect'), "base64").toString("ascii") : '/')
    }

    return (
        <div className='mb-5'>            
        <h3 className="text-gradient text-primary mb-4">Login</h3>
        <Card className="border text-xl shadow">
            <CardHeader className="text-white bg-secondary t-bold">
                Enter an Authorised Email Address and Password
            </CardHeader>
            <CardBody>
                <div className="pb-1">
                    <Alert color="warning" className="border-warning">
                        You must adhere to the Rotherham Health Record Access Control Policy before logging in or using this system.
                        <hr />
                        Click <a className="alert-link" href="https://rotherhamhealthrecord.org/wp-content/uploads/2021/06/Access-Control-Policy-RHR-V2-FINAL.pdf" target="_blank">here</a>  for the policy.
                    </Alert>
                </div>

                <FinalForm
                    onSubmit={(values: IUserFormValues) =>
                        login(values).then(
                            (response) => {
                                let audit: AddEntry = {
                                    module: "User",
                                    description: `${rootStore.userStore.user.username} has logged in`,
                                    username: rootStore.userStore.user.username,
                                    action: "Login",
                                    documentId: "",
                                    patientId: "",
                                    visitId: "",
                                    practiceId: (rootStore.userStore.user.practice?.name || '')
                                };
                                rootStore.auditStore.addEntry(audit);
                                return response;
                            }).
                            then(() => {
                                redirectAfterLogin();
                            })
                            .catch(
                                error => ({ [FORM_ERROR]: error })
                            )}
                    render={({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="RhrEmail">Email</Label>
                                <Field className="form-control" type="email" name="email" component="input" id="RhrEmail" placeholder="Enter Email"></Field>
                            </FormGroup>
                            <FormGroup>
                                <Label for="Rhrpassword">Password</Label>
                                <Field className="form-control" type="password" name="password" component="input" id="Rhrpassword" placeholder="Enter Password" />
                            </FormGroup>
                            <a href="/resetpassword" className="btn btn-outline-secondary float-right">Reset Password</a>
                            <Button type="submit" color="primary">Login</Button>
                        </Form>
                        )} />
            </CardBody>
        </Card> 
        </div>
    );
};

export default LoginForm; 