import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";
import { IInpatient } from "app/models/inpatient";

interface IWardBedProps {
    patient?: IInpatient | null;
    bedCount?: number;
}

const WardBed: React.FC<IWardBedProps> = ({ patient, bedCount }) => {
    
    const bgColor = (opacity: string) => {
        return patient.patient?.gender === "F" ? 
                `rgba(200, 50, 150, ${opacity})` :
                patient.patient?.gender === "M" ?
                `rgba(50, 150, 200, ${opacity})` :
                `rgba(200, 200, 200, ${opacity})`;
    }

    const bedSizeClass = () => {
        if (bedCount > 6)
            return "col-6 col-sm-2";
        if (bedCount >= 3)
            return "col-12 col-sm-4";
        else if (bedCount == 2)
            return "col-12 col-sm-6";
        else if (bedCount == 1)
            return "col-12";
    }

    return (
        <div className={`px-1 pb-2 ${bedSizeClass()}`}>
            <div style={{border:`solid 1px ${bgColor('.5')}`}}>
                <div className="text-white border rounded position-relative lh-xs-115" style={{height:'87px', backgroundColor:bgColor('.25')}}>
                    
                    {/*  Bed Number  */}
                    <div className="position-absolute text-center bg-light font-weight-bold rounded-circle text-sm px-1 py-1" 
                    style={{border:`solid 2px ${bgColor('.9')}`, color:bgColor('.95'), left:'-7px', top: '5px', height: '25px', width: '25px'}}>
                        {patient.currentLocation.bedNumber}
                    </div>

                    <div className="pl-4 text-md py-1 text-truncate" style={{backgroundColor:bgColor('.75')}}>
                        {/*  Patient Name, Gender and Age  */}
                        <span className="font-weight-bold pr-2">
                            {patient.patient?.name?.forename} {patient.patient?.name?.surname}
                        </span>
                        {patient.patient?.gender} {patient.patient?.age}
                        
                        {/*  NHS Number  */}
                        {patient.patient?.nhsNumber?.value ?
                        <div className="text-xs text-light">
                            NHS:{patient.patient?.nhsNumber?.value.indexOf(' ') === -1 ? 
                                <>{patient.patient?.nhsNumber?.value.substring(0,3)} {patient.patient?.nhsNumber?.value.substring(3,6)} {patient.patient?.nhsNumber?.value.substring(6,10)}</> :
                                   patient.patient?.nhsNumber?.value}
                        </div> : <></>}
                    </div>
                    <div className="row text-dark">
                        <div className="col-4 lh-xs-1">
                            {/*  Attending Clinician Surname  */}
                            <div className="pl-1 pt-1 text-xs font-weight-bold text-truncate">
                                {patient.admissionDetails?.attendingClinician?.name?.surname}  
                            </div>
                            {/*  Bed Specialty  */}
                            <div className="pl-1 text-xxs">
                                {patient.currentLocation.specialty.description}        
                            </div>
                            
                        </div>
                        <div className="col-4">
                            <div className="text-xxs mt-1">ADM:</div>
                            <div className="text-xs font-weight-bold" dangerouslySetInnerHTML={{ __html: patient.lengthOfStay}}>
                                
                            </div>
                            
                        </div>
                        <div className="col-4">
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default observer(WardBed);