import { useMsal } from "@azure/msal-react";
import { Button } from "reactstrap";

export const SignOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <div>
            <Button
                onClick={() => handleLogout()}
                color="secondary">
                SSO Logout
            </Button>
        </div>
    )
};