import React from "react";
import { DocumentInformation,  IDocumentValues,} from "../../app/models/inpatient";
import { ListGroupItem,  PopoverBody, UncontrolledPopover, ListGroup} from "reactstrap";
import { observer } from "mobx-react-lite";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from  "@fortawesome/react-fontawesome";
import agent from "app/api/agent";
import PatientRecordListDisplay, { ISpecialFilter, ISort } from "./common/PatientRecordListDisplay";
import { useHistory } from "react-router-dom"
import { ShortDateOnlyFormat, ShortTimeOnlyFormat } from "./common/DateTimeFunctions";

const PatientRecordDocumentsList: React.FC = () => {
  let history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const _filters: any[] = [
    { 
      label: "Specialty", 
      accessPath: "visit.admissionDetails.specialty.description"
    },        
    { 
      label: "Status", 
      accessPath: "status"
    },        
    { 
      label: "Account", 
      accessPath: "visit.systemPublicIdentifier.value"
    },
    { 
      label: "Date", 
      accessPath: "saveDateTime", 
      specialType: "Date" 
    } as ISpecialFilter
  ]
  const _sorts: ISort[] = [    
    { label: "Date", accessPath: "saveDateTime", type: "Date" },
    { label: "Name", accessPath: "type.description", type: "String" },
    { label: "Account #", accessPath: "visit.systemPublicIdentifier.value", type: "String" }
  ]

  const loadData = () => {
    return agent.Patient.Documents({
      systemIdentifier: urlParams.get("p"),
      patientIdentifier: null,
      visitIdentifier: null,
    })
  };

  const DownloadDocument = (visitId: string, docId: string) => {
    let values: IDocumentValues = { systemIdentifier: visitId, docId: docId };
    agent.Patient.CacheDocument(values).then((response) => {
      agent.Patient.PdfDocument(values).then((blob) => {
        const url = URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
        window.open(url);
      });
    });
  };

  const onClick = (document) => {
    if (document.status === "Scanned")
      DownloadDocument(document.visit.systemInternalIdentifier.value,document.systemInternalIdentifier.value);
    else 
      history.push(`${process.env.REACT_APP_URL_PATIENT_RECORD}/document?p=${urlParams.get("p")}&v=${urlParams.get("v")}&d=${document.systemInternalIdentifier.value}`)
  }

  const displayTemplate = (document: DocumentInformation, index: number) => {
    return (
    <ListGroupItem
      className="list-group-item-action"
      key={index} 
      onClick={() => onClick(document)}
      >
    <div className="row">
      
    <div className="col-sm-5 col-lg-7">
        <div className="t-bold">{document.type.description}</div>
        {document.author.name?.surname && (
          <div className="small text-muted">
            Author
            <FontAwesomeIcon icon={faUserMd} className="mx-1" />
            <a
              className="text-primary"
              id={"auth" + index}
              type="hover"
            >
              {document.author.name.forename}{" "}
              {document.author.name.surname}
            </a>
            <UncontrolledPopover
              trigger="hover"
              placement="bottom"
              target={"auth" + index}
            >
              <PopoverBody>
                <strong>
                  {document.author.name.forename}{" "}
                  {document.author.name.surname}
                </strong>
                <br />{" "}
                {document.author.primarySpecialty.description}
                <br />
                {document.author.role}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        )}
      </div>
      <div className="col-sm-3 col-lg-2">
        <div className="text-muted small">
          {document.visit.systemPublicIdentifier.value}
        </div>
      </div>

      <div className="col-sm-4 col-lg-3 text-center lh-xs-115">
        <div className="float-right float-end">
          <span
            className={
              "badge text-md badge-" + 
              (document.status === "Signed" ? "success" : 
              (document.status === "Pending" ? "warning" : "secondary"))
            }>
            {" "}
            {document.status}
          </span>
        </div>
        <div>
          {ShortDateOnlyFormat(document.saveDateTime)}
        </div>
        <div className="text-xl">
         {ShortTimeOnlyFormat(document.saveDateTime)}
        </div>
      </div>

    </div>
  </ListGroupItem>
  )}

  return  (
    <PatientRecordListDisplay 
    _agentCall={loadData}
    _displayTemplate={displayTemplate}
    _filters={_filters} 
    _sorts={_sorts}>
    </PatientRecordListDisplay>
  )
};
export default observer(PatientRecordDocumentsList);
