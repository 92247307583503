import React, { Fragment } from "react";
import { Queries } from "../../app/models/inpatient";
import { observer } from "mobx-react-lite";
import agent, { DataLoadStatus } from "app/api/agent";
import { ShortDateTimeFormat } from "./common/DateTimeFunctions";
import PatientRecordSummaryDisplay from "./common/PatientRecordSummaryDisplay";
import { UncontrolledPopover, Popover, PopoverHeader, PopoverBody, ListGroup, ListGroupItem, Col, Row, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBug, faClock, faExternalLinkSquareAlt, faFistRaised, faPills, faRunning, faShieldAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface IQueryDisplay {
  queries: Queries[],
  icon: IconDefinition,
  title: string,
  color: string
}

const PatientRecordSummaryQueries: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const loadData = () => 
    agent.Patient.Queries({
      systemIdentifier: urlParams.get("p"),
      patientIdentifier: null,
      visitIdentifier: null
    })

    const QuerySets = (queries: Queries[]) : IQueryDisplay[] => {
      
      return queries && [
        { queries: queries.filter((item: Queries) => item.grouping === "Safeguarding"), icon: faShieldAlt, title: "Safeguarding", color: "danger" },
        { queries: queries.filter((item: Queries) => item.grouping === "Social Services"), icon: faExternalLinkSquareAlt, title: "Social Services", color: "female" },
        { queries: queries.filter((item: Queries) => item.grouping === "Infection Control"), icon: faBug, title: "Infection Control", color: "danger" },
        { queries: queries.filter((item: Queries) => item.query.id === "SI.VA"), icon: faFistRaised, title: "Violent/Abusive", color: "danger" },
        { queries: queries.filter((item: Queries) => item.query.id === "FALLS.RISK" && item.responseValue.startsWith("Y")), icon: faRunning, title: "Falls Risk", color: "info" },
        { queries: queries.filter((item: Queries) => item.query.id === "SI.DOLS"), icon: faBars, title: "Deprivation of Liberties Safeguarding", color: "danger" },
        { queries: queries.filter((item: Queries) => item.query.id === "SI.LD"), icon: faBars, title: "Learning Disabilities", color: "dark" },
 //      { queries: queries.filter((item: Queries) => item.query.id === "SI.DEM"), icon: "fas fa-flower", title: "Known Dementia Diagnosis", color: "dark" },
        { queries: queries.filter((item: Queries) => item.query.id === "SI.MDS"), icon: faPills, title: "Medicines Dosage System (MDS)", color: "dark" },
        { queries: queries.filter((item: Queries) => item.query.id === "PDD"), icon: faClock, title: "Estimated Date of Discharge", color: "success" }
      ] || []
    }    
    

    const QuerySetCount = (queries: Queries[]) : number => {
       return QuerySets(queries).filter(qs => qs.queries.length > 0).length;
    }   

  const displayTemplate = (data: any, status: DataLoadStatus, loader: React.ReactNode) => {

    if (status === DataLoadStatus.Loading)
      return loader;
      
    if (status === DataLoadStatus.Failed)
      return <></>;
      
    return (
    <div>
      {QuerySetCount(data) > 0 ?
      <>
      <div className="bg-white text-info py-1 pl-2 text-sm mb-3 border rounded">
        Hover each icon for more information about the flag or indicator.
      </div>
    {QuerySets(data).map((querySet: IQueryDisplay, index: number) => (
      <Fragment key={index}>
      {querySet.queries.length > 0 &&
      <>
        <div className="rounded border bg-white text-xl mb-2 mr-2 d-inline-block py-2 px-3 text-center shadow-sm icon-hover" id={`qs${index}`} title={querySet.title} data-toggle="popover tooltip" data-trigger="hover click">
          <FontAwesomeIcon icon={querySet.icon} className={`text-${querySet.color}`} />
        </div>    
            <UncontrolledPopover style={{ 'width': '400px' }} trigger="hover click" placement="bottom" target={`qs${index}`}>
                <PopoverHeader className="bg-header">{querySet.title}</PopoverHeader>
                <PopoverBody>
                    <ListGroup className="list-group list-group-flush p-0" style={{ lineHeight: 1.15 }}>
                        {querySet.queries.map((query, index) => (
                            <ListGroupItem key={index} className="p-2">
                                <Row>
                                  <Col>
                                    {query.query?.description}
                                  </Col>
                                  <Col>
                                    {query.responseValue}
                                    <div className="text-xs text-secondary">{ShortDateTimeFormat(query.responseDateTime)}</div>
                                  </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </PopoverBody>
            </UncontrolledPopover>
      </>
      }
      </Fragment>
    ))}</> : 
    <Table size="sm"><tbody><tr className="table-warning text-sm text-secondary"><td className="px-2">No flags or indicators found.</td></tr></tbody></Table>
    }
    </div>
  )}
  
  return (
    <PatientRecordSummaryDisplay _agentCall={loadData} _displayTemplate={displayTemplate} _title="Queries and Indicators" />
  )
}
export default observer(PatientRecordSummaryQueries);
