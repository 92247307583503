import React, { useState, useEffect, useContext, ReactNode } from "react";
import { Row, Col, Container, Card, CardBody, Nav, Navbar } from "reactstrap";
import { observer } from "mobx-react-lite";
import WardBed from "./WardBed";

interface IWardBayProps {
    room: string,
    bedCount: number,
    children?: ReactNode
}

const WardBay: React.FC<IWardBayProps> = ({room, bedCount, children}) => {

    const baySizeClass = () => {
        if (bedCount > 6)
            return "col-12";
        else if (bedCount >= 3)
            return "col-12 col-sm-6";
        else if (bedCount == 2)
            return "col-6 col-sm-4";
        else if (bedCount == 1)
            return "col-6 col-sm-4 col-md-3 col-lg-2";
    }

    return (
        <div className={`mb-2 px-2 pb-2 ${baySizeClass()}`}>
            <div className="border shadow-sm">
                <div className="text-dark text-sm px-2 font-weight-bold" style={{backgroundColor:'rgba(30, 110, 185,.25)'}}>
                    {room}
                </div>
                <div className="px-4 pb-2 pt-3 bg-light">
                    <div className="row">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default observer(WardBay);