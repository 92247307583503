import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "reactstrap";
import { ShortDateOnlyFormat } from "../common/DateTimeFunctions";
import { IFhirProcedure } from "app/models/fhir";

interface IFhirProcedureProps {
    procedure: IFhirProcedure
}

const YhcrProcedures: React.FC<IFhirProcedureProps> = ({ procedure }) => {

    return (
        <Fragment>
            <Col className="py-2">       
                <div className={`font-weight-bold text-sm pr-2 mr-2 lh-xs-115 text-lg mb-2`}>
                    {procedure.code.coding.map(c => c.display?.value) || <span className="text-danger">!! Error: Procedure details not supplied !!</span>}   
                    {procedure.category?.coding?.map((cat,cati) => (
                        <div className="text-sm text-primary" key={cati}>
                            {cat.display?.value}
                        </div>
                    ))}
                    <div className="text-sm text-secondary font-weight-normal">
                        {procedure.bodySite ? 
                        <div>Body site(s): 
                            {procedure.bodySite?.map((bs, bsi) => (
                                <span key={bsi}> &bull; {bs.coding.map((bsc, bsci) => <Fragment key={bsci}>{bsc.display?.value}</Fragment>)} </span>
                            ))} 
                        </div> : <></> }
                    </div>
                </div>
            </Col>
            
            <Col xl={3} lg={4} sm={6} xs={5} className="text-center text-sm lh-xs-115 py-2">
                <Row>
                <div className="col-6">
                    <div className="text-xs text-secondary">Performed</div>
                        {procedure.performedDateTime ? ShortDateOnlyFormat(procedure.performedDateTime?.value) : "-"}
                    </div>
                <div className="col-6">
                    <div className="text-xs text-secondary">Performer</div>
                        {procedure.performer && procedure.performer.length > 0 ? 
                            procedure.performer.map((a, ai) => (<div>{a.actor.display?.value}</div>)) : <>-</>}
                    </div>
                </Row>
            </Col>
            <Col xs="2" className="py-2 text-center">
                <span className={`badge text-md badge-${procedure.status?.value === "completed" ? "success" : (procedure.status?.value === "in-progress" ? "primary" : "secondary")}`}>{procedure.status?.value}</span><br />
            </Col>
        </Fragment>
    )

}
export default observer(YhcrProcedures)