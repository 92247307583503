import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Card, CardBody, Nav, Navbar } from "reactstrap";
import { observer } from "mobx-react-lite";

interface IWardHeaderProps {
    location: string
}

const WardHeader: React.FC<IWardHeaderProps> = ({location}) => {
    
    return (
        <Navbar className="bg-dark" dark>
            <p className="navbar-text text-light">
                {location || 'Ward Header'}
            </p>
        </Navbar>
    )
}
export default observer(WardHeader);