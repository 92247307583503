import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form'
import { Button, Form, FormGroup, Label, Card,CardBody, Container,Row,Col, CardHeader } from 'reactstrap';
import { RootStoreContext } from '../../app/stores/rootStore';
import { ISetupPasswordForm } from '../../app/models/user';
import agent from '../../app/api/agent';

const PasswordReset = () => {
    let rootStore = useContext(RootStoreContext);
    let urlParams = new URLSearchParams(window.location.search);

    return (
        <Container fluid>
            <Row className="justify-content-center">
                <Col xs="10" md="6" className="pt-5">
                    <Card>
                        <CardHeader className="text-white bg-primary t-bold">
                            Rotherham Health Record Password Reset
                        </CardHeader>
                        <CardBody>
                            <FinalForm
                                onSubmit={(values: ISetupPasswordForm) => {
                          
                             
                                    agent.User.resetpassword(values).then((response) => {
                                    })}
                                    }
                                
                   
                                render={({ handleSubmit }) => (
                                    
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label for="password">Email</Label>
                                            <Field className="form-control" type="email" name="email" component="input" id="email" placeholder="Enter Email" />
                                        </FormGroup>

                                        <Button type="submit" color="primary">Submit</Button>
                                    </Form>
                                    )}
                                />
                        </CardBody>
                        </Card>
                </Col>
            </Row>
       </Container>
    );
};

export default PasswordReset;